import aboutThumb1 from "../../images/blog/ab-img.png";
import aboutThumb2 from "../../images/blog/ab-img2.png";
import aboutIcon from "../../images/icon/ab-icon-image.png";

const data = {
  aboutDescription1:
    "In the digital world of the BASE chain, a unique character emerged, captivating everyone's attention. Named $BIRDIE, this vibrant and quirky creature wasn't just any digital asset; it was a one-of-a-kind NFT (Non-Fungible Token) intertwined with meme culture. $BIRDIE, with its colorful feathers and playful antics, quickly became a symbol of joy and creativity across the internet.",
  aboutDescription2:
    `As Birdie's popularity soared, it reminded everyone of the fun and freedom found in the digital realm. Its existence as an NFT on the BASE chain represented a blend of authenticity and digital artistry, making it a beacon of innovation in the blockchain world. Birdie's journey from a simple NFT to a beloved meme showcased the power of creativity and connection in the vast digital landscape.`,
  artists: [
    {
      thumb: aboutThumb1,
      icon: aboutIcon,
      name: "Birdie Creator",
      role: "Artist",
    },
    {
      thumb: aboutThumb2,
      icon: aboutIcon,
      name: "Birdie Creator",
      role: "Artist",
    },
  ],
};

export default data;
