import { useState } from "react";
import { useModal } from "../../../../utils/ModalContext";

import CountdownTimer from "react-component-countdown-timer";

import Button from "../../../../common/button";
import Particle from "../../../../common/particle/v2";

import bannerThumb1 from "../../../../assets/images/banner/Item1.png";
import bannerThumb2 from "../../../../assets/images/banner/Item2.png";
import bannerThumb3 from "../../../../assets/images/banner/Item3.png";
import BannerStyleWrapper from "./Banner.style";
import CustomModal  from "../../../../common/modal/customModal";
const Banner = () => {
  const { mintModalHandle, connectWalletModalHanlde, account } = useModal();
  const [count, setCount] = useState(1);
    const settings = {
    count: calculateCountdown(), // Calculate the countdown in seconds
    showTitle: true, 
    labelSize: 14,
    backgroundColor: "transparent",
    color: "rgba(82,135,242,0.8)",
    dayTitle: "",
    hourTitle: "",
    minuteTitle: "",
    secondTitle: "", 
    id: "countdownwrap",
};
function calculateCountdown() {
    // Target datetime (March 26, 2024)
    const targetDate = new Date(2024, 4, 30); // Note: Months are zero-based (0 for January, 1 for February, etc.)
    
    // Current datetime
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const difference = targetDate.getTime() - currentDate.getTime();

    // Convert milliseconds to seconds
    return Math.floor(difference / 1000);
}
const [isModalVisible, setIsModalVisible] = useState(false);
function copyClipboard() {
    const textToCopy = document.getElementById('mytxt').value;

    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          setIsModalVisible(true);
          setTimeout(() => setIsModalVisible(false), 2000); // Auto-close the modal after 2 seconds
        })
        .catch((err) => {
          console.error('Could not copy text: ', err);
        });
    } else {
      // Fallback for older browsers or non-secure contexts
      // Similar approach with textarea and document.execCommand
    }
  }

  return (
    <BannerStyleWrapper className="bithu_v3_baner_sect" id="home">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="banner-image-area3">
              {/* particles component */}
              <Particle />
              <img
                className="banner-image banner-image1"
                src={bannerThumb1}
                alt="bithu banner thumb"
              />
              <img
                className="banner-image banner-image2"
                src={bannerThumb2}
                alt="bithu banner thumb"
              />
              <img
                className="banner-image banner-image3"
                src={bannerThumb3}
                alt="bithu banner thumb"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="banner-conent3">
              
              <h1 className="banner-title text-uppercase">Presale is live now</h1>
              <div className="bithu_v3_timer">
                <h5 className="text-uppercase">Presale end in</h5>
                <div className="timer timer_1">
                  <CountdownTimer {...settings} />
                </div>
              </div>
              <div className="banner-count-inner d-flex align-items-center">
                <div className="banner-btn-area">
                 
                  <input
                    id="mytxt" 
                    className="input-number"
                    type="text"
                    style={{ width:"fit-content","min-width":'400px' }}
                    value="0x4179b8603E2bb0542a64F0434c301f3e4FF3Cd21"
                    onload={(e) => e.target.style.width = (e.targetvalue.length) }
                  />
                  
                    
                </div>
                <div className="bithu_v3_baner_buttons">
                <Button lg variant="mint" onClick={() => copyClipboard()}>
                    {" "}
                    Copy
                  </Button>
                </div>
              </div>
              <div className="banner-bottom-text text-uppercase">
                $BIRDIE ON $BASE <br/>
                Minimum 0.05 ETH <br/> 50% Presale <br/> 50% LP <br/>
                LIQUIDITY BURN <br/> OWNERSHIP REVOKED
              </div>
            </div>
          </div>
        </div>
      </div>
       <div>
      {/* Existing Banner component code */}
      <CustomModal 
        isVisible={isModalVisible} 
        message="Wallet Address Copied" 
        onClose={() => setIsModalVisible(false)} 
      />
    </div>
    </BannerStyleWrapper>


  );
};

export default Banner;
