const data = [
    {
        num: "1",
        mintColor: "green",
        text: "Go To DEX SWAP"
    },
    {
        num: "2",
        mintColor: "blue",
        text: "Copy Paste Contract Address"
    },
    {
        num: "3",
        mintColor: "yellow",
        text: "Buy $BIRD"
    },
    {
        num: "4",
        mintColor: "red",
        text: "Hold and CHARM !"
    },
]

export default data;