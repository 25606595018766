import checkIcon from "../../images/blog/arrow.png";
import thumb1 from "../../images/blog/roadmap-img.png";
import thumb2 from "../../images/blog/roadmap-img2.png"; 
import thumb3 from "../../images/blog/roadmap-img3.png";
import thumb4 from "../../images/blog/roadmap-img4.png";
import thumb5 from "../../images/blog/roadmap-img5.png";
const data = [
  {
    progressIcon: checkIcon,
    progress: "10",
    title: "Launch Website",
    text: "Our website launches first day, offering innovative solutions and seamless user experiences. Explore, engage, and elevate your digital journey with us!",
    thumb: thumb1,
  },
  {
    progressIcon: checkIcon,
    progress: "20",
    title: "Pre-sale Start",
    text: "Pre-sale kicks off ! Secure exclusive deals on our groundbreaking products. Limited time only ,act fast to grab your spot in the future.",
    thumb: thumb2,
  },
  {
    progressIcon: checkIcon,
    progress: "50",
    title: "Listing CEX",
    text: "Now live on a leading centralized exchange! Dive into the market and trade with confidence. A new era of accessibility and growth awaits.",
    thumb: thumb3,
  },
  {
    progressIcon: checkIcon,
    progress: "75",
    title: "Launch NFT Marketplace",
    text: "Our NFT Marketplace is now live! Connect, create, and trade unique digital assets $BIRDIE. Immerse yourself in the art of the future, today.",
    thumb: thumb4,
  },
  {
    progressIcon: checkIcon,
    progress: "100",
    title: "Launch STAKING",
    text: "Staking platform launched! Earn rewards by staking your tokens. Secure, simple, and rewarding ,start growing your investment today.",
    thumb: thumb5,
  },
];

export default data;
