const data = [
  {
    title: "What is $BIRDIE ?",
    text: "$BIRDIE is the number one of the BASE CHAIN ecosystem. Protecting, vibing and growing the $BIRDIE community.",
  },
  {
    title: "What Chain is $BIRDIE on?",
    text: "$BIRDIE is on BASE Chain.",
  },
  {
    title: "How we can buy and invest $BIRDIE ?",
    text: "Make sure you download the Metamask Wallet app or browser extension for you phone or browser. Simply purchase $BIRDIE using BASE-ETH through a DEX like Uniswap. Easy!",
  },
  {
    title: "Where we can buy and sell $BIRDIE NFT ?",
    text: "You can buy and sell $BIRDIE NFT after marketplace NFT is launch.",
  },

  {
    title: "What is $BIRDIE contract address ?",
    text: "Now it's not yet launched for contract address",
  },
];

export default data;
