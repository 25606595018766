import React from 'react';

const CustomModal = ({ isVisible, message, onClose }) => {
  if (!isVisible) return null;

  return (
    <div style={{
      position: 'fixed', 
      top: '50%', 
      left: '50%', 
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      padding: '20px',
      zIndex: 1000,
      borderRadius: '10px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
    }}>
      <p style={{ color:  '#2D65F8'}}>{message}</p>
      <button onClick={onClose} style={{
        marginTop: '10px',
        padding: '5px 10px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#007bff',
        color: 'white',
        cursor: 'pointer'
      }}>Close</button>
    </div>
  );
};

export default CustomModal;