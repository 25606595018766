import { Routes, Route } from "react-router-dom";
import HomeV3 from "../pages/homeV3";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeV3 />} exact />
      <Route path="/home-three" element={<HomeV3 />} />
    </Routes>
  );
}

export default App;
